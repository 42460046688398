import React from "react";
import Header from '../components/header';
import * as faqstyles from "./faq.module.css"

const CardQ = ({answers, question}) => {
    return (
        <div className={faqstyles.questioncard}>
            <Header headerText={question} headingTag="h6" classTag="h6" />
            {answers.map((el, index) =>(
                <p key={index}>{el}</p>
            ))}
        </div>
    )
}

export default function FAQ({faqcontent}) {
    return(
        <div className={faqstyles.questioncardgroup}>
            {
                faqcontent.map((el, index) => (
                    <CardQ key={index} answers={el.answer} question={el.question}  />
                ))
            }
        </div>
    )
}