import React from "react"
import * as styles from "./gen-video.module.css"

export default function GenVideo({vidsource, title}) {
    return (
        <div className="center-text">
            <iframe
                className={styles.iframe}
                src={"https://www.youtube.com/embed/"+vidsource}
                title={title + " video"}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
            />    
        </div>
    )
}