// extracted by mini-css-extract-plugin
export var control = "gen-search-module--control--HWJSp";
export var errorMsg = "gen-search-module--errorMsg--hceL1";
export var field = "gen-search-module--field--VUJnI";
export var formbtn = "gen-search-module--formbtn--O1xll";
export var group = "gen-search-module--group--BrGJe";
export var input = "gen-search-module--input--II9Hm";
export var inputReadonly = "gen-search-module--input-readonly--dTOXI";
export var ismultiple = "gen-search-module--ismultiple--jnNuY";
export var label = "gen-search-module--label--Bwtw7";
export var select = "gen-search-module--select--sjjhF";
export var textarea = "gen-search-module--textarea--FPatj";