import React from "react"
import MainImageSlider from "../components/main-image-slider"
import * as singleproductstyles from "./single-product.module.css"

export default function SingleProduct({name, price, url, image, button, excerpt, tabs, gallery}) {
    
    function createMarkup() {
        return {__html: tabs[0].tab_content_html}
    }

    return (
        <div>
            <div className={singleproductstyles.contentcard} dangerouslySetInnerHTML={createMarkup()}></div>
            <MainImageSlider gallerycontent={gallery} />
        </div>
    )
}