import React from "react"
import Header from "../components/header"
import GenButton from "../components/gen-button"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./page-head-section.module.css"

export default function PageHeadSection({content, productinfo = null}) {

    let curProductInfo = productinfo ? productinfo.edges[0].node : null;
    
    const ctn = {
        title: content.title,
        excerpt: content.excerpt,
        updated: content.updated,
        buttons: content.buttons,
        product: curProductInfo
    }
    
    let btns = ctn.buttons;
    let btnArray = [];

    const data = useStaticQuery(graphql`
        query {
            productimg: allFile(filter: {relativePath: {regex: "/images\/products/"}}){
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                width: 200
                            )
                        }
                    }
                }
            }
        }
    `)

    let curImage, gotCurImage;
    if(ctn.product && ctn.product.image_thumb.length > 0){
        let imgSource = ctn.product.image_thumb;
        const image = data.productimg.edges.find(n => {
            return n.node.relativePath.includes(imgSource);
        })

        if(!image) {
            return null;
        }
        gotCurImage = getImage(image.node);
        curImage = <GatsbyImage image={gotCurImage} alt={ctn.product.name} className={styles.image} />
    }

    btns.forEach((el, index) => {
        btnArray.push(<GenButton key={index} link={el.link} button={el.button} rel={el.rel} target={el.targetLink} color={el.color} />)
    });
    
    return(
        <section className={["medium", styles.pageHead].join(" ")}>
            <div className={styles.flex}>
                <div className={styles.col1}>
                    <Header headerText={ ctn.title }></Header>  
                    <p>{ ctn.excerpt }</p>
                    {ctn.product && <div><strong><b>Starting at ${ctn.product.start_price} USD</b></strong></div>}
                    { btnArray }
                </div>
                <div className={styles.col2}>
                    {ctn.product && curImage}
                    <p>Updated: { ctn.updated }</p>
                </div>
            </div>
        </section>
    )
}