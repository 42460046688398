import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"
import * as teamgroupstyles from "./team-group.module.css"

const MemberCard = ({title, tags, image, content }) => {

    const data = useStaticQuery(graphql`
        query {
            profilepic: allFile(filter: {relativePath: {regex: "/images\/profile/"}}) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 300, maxHeight: 300) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)

    let imageSection;

    if(image.length > 0) {
        let imgSource = image;
        const curImg = data.profilepic.edges.find(n => {
            return n.node.relativePath.includes(imgSource)
        })   

        if(!image) {
            return null
        }

        imageSection = <Img fluid={curImg.node.childImageSharp.fluid} className={teamgroupstyles.teampic} />

    } else {
        imageSection = null;
    }

    return (

        <section className="small">
            <div className={teamgroupstyles.memberheader}>
                <div>
                    <Header headerText={title} classTag="h3" headingTag="h2" />
                    <div className={teamgroupstyles.taggroup}>
                        {tags.map((el, index) => (
                            <span key={index} className={teamgroupstyles.tag}>{el}</span>
                        ))}
                    </div>
                </div>
                {imageSection}
            </div>
            {content.map((el, index)=>(
                <p key={index}>{el}</p>
            ))}
        </section>
    )
}

export default function MemberGroup({memberContent}) {
    let memberKey = memberContent.edges
    
    return(
    <div>
      {
          memberKey.map(({node}, index) => (
            <MemberCard key={index} title={node.title} tags={node.tags} image={node.image} content={node.content} />
          ))
      }
    </div>
    )
}

MemberCard.propTypes = {
    title: PropTypes.string,
    tags: PropTypes.array,
    image: PropTypes.string,
    content: PropTypes.array
}
