import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeadSection from "../components/page-head-section"
import TeamGroup from "../components/team-group"
import TwoThirds from "../components/two-thirds-layout"
import PageContent from "../components/page-content"
import CardGroup from "../components/card-group"
import SingleProduct from "../components/single-product"
import RelatedProducts from "../components/related-product"
import MainSlider from "../components/main-slider"
import FAQ from "../components/faq"
import GroupLinks from "../components/links"
import GenTable from "../components/gen-table"
import GenMap from "../components/gen-map"
import GenSearch from "../components/gen-search"
import GenVideo from "../components/gen-video"
import GenForm from "../components/gen-contact-form"
import GenButton from "../components/gen-button"
import ProductTable from "../components/product-table"

export default function PageTemplate({ data }) {
    const page = data.pagesJson
    const members = data.members
    const product = data.product
    const related = data.related

    //page info
    let showPage;
    if (page.show_page_info) {
        showPage = <PageHeadSection content={page.pageInfo} productinfo={product.edges.length > 0 ? product : null}/>
    }

    //main slider
    let mainSlider;
    if(page.slider.length > 0) {
        mainSlider = <MainSlider slider={page.slider} classid={page.slider_class_identifier} direction={page.slider_direction} />
    }

    //content type
    const pageMainContent = page.main_content;
    let slider, map, team, pageContent, links, faq, cardGroup, genTable, singleProduct, productTable, video, search;
    let contentPage = [];

    //needs to go through an array, either map or foreach
    pageMainContent.forEach((el, index) => {
        if (el.type === "slider") {
            slider = <MainSlider key={index} slider={el.slider} classid={el.slider_class_identifier} direction={el.slider_direction} />
            contentPage.push(slider);
        } else if (el.type === "map"){
            if(el.map_info !== null) {
                map = <GenMap key={index} position={el.map_info.position} zoom={el.map_info.zoom} pops={el.map_info.popups} />
                contentPage.push(map);
            } 
        } else if (el.type === "team") {
            if (page.member.length > 0) {
                team = <TeamGroup key={index} memberContent={members} />
                contentPage.push(team);
            }
        } else if (el.type === "page-content"){
            pageContent = <PageContent key={index} mainContent={el} />
            contentPage.push(pageContent)
        } else if (el.type === "links") {
            links = <GroupLinks key={index} linkcontent={el.group_links} />
            contentPage.push(links);
        } else if (el.type === "faq") {
            faq = <FAQ key={index} faqcontent={el.faq_list} />
            contentPage.push(faq);
        } else if (el.type === "card-group") {
            let cardGroupArr = el.card_group
            cardGroupArr.forEach((el, index) => {
                cardGroup = <CardGroup key={index + "card"} services={el.group}  title={el.group_title} description={el.group_description} />
                contentPage.push(cardGroup);
            })
        } else if (el.type === "gen-table") {
            genTable = <GenTable key={index} tablecontent={el.table_content} />
            contentPage.push(genTable);
        } else if (el.type === "single-product") {
            let productEdges = product.edges
            productEdges.forEach(({ node }, index) => {
                singleProduct = <SingleProduct key={index + "singleProduct"} name={node.name} tabs={node.tab} url={node.url} price={node.start_price} excerpt={node.excerpt} gallery={node.image_gallery} />
                productTable = <ProductTable key={index + 'productTable'} title={'US Pricing List'} tablecontent={node.variations} />
                contentPage.push(productTable);
                contentPage.push(singleProduct);
            })
            
        } else if (el.type === "video") {
            video = <GenVideo key={index} vidsource={el.video_source} title={page.pageInfo.title} />
            contentPage.push(video);
        } else if (el.type === "search") {
            search = <GenSearch key={index} />
            contentPage.push(search);
        }
    })

    //product Slider
    let productSlider;
    if(page.group_slider_id !== "") {
        productSlider = <RelatedProducts products={related} />
    }

    //sample sidebar
    const pageSideBar = [
        {
            "link": "shop/",
            "button": "Product List",
            "rel": "noopener",
            "targetLink": "_self",
            "color": "white"
          },
          {
            "link": "https://store.ifi-test.com/collections",
            "button": "Shopify Store",
            "rel": "noopener",
            "targetLink": "_target",
            "color": "white"
          }
    ]
    const sampleSidebar = pageSideBar.map((el, index) => {
        return <GenButton key={index} link={el.link} button={el.button} rel={el.rel} target={el.targetLink} color={el.color} />
    })
    
    return (
        <Layout>
            <SEO title={page.seo.title} description={page.seo.description}/>
            { showPage }
            { mainSlider }
            <TwoThirds
                left={ contentPage  }
                right={ sampleSidebar }
            />
            { productSlider }
            <GenForm />
        </Layout>
    )
}

export const query = graphql`
    query($url: String!, $member: [String], $related: String, $product: String) {
        pagesJson(url: { eq: $url}) {
            name,
            show_page_info,
            url,
            member, 
            pageInfo {
                title,
                excerpt,
                updated,
                buttons {
                    link,
                    button,
                    rel,
                    targetLink,
                    color
                }
            },
            seo {
                title,
                description,
                keywords,
                author,
                og_type,
                og_image,
                twitter_card,
                twitter_image
            },
            slider_class_identifier,
            slider_direction,
            slider {
                title,
                content,
                image,
                color_background,
                container_color,
                text_align
                buttons {
                    link,
                    button,
                    rel,
                    targetLink
                }
            },
            group_slider_id,
            main_content {
                type,
                video_source,
                show_slider,
                slider_class_identifier,
                slider_direction,
                slider {
                    title,
                    content,
                    image,
                    color_background,
                    container_color,
                    text_align,
                    buttons {
                        link,
                        button,
                        rel,
                        targetLink
                    }
                }
                map_type,
                map_info {
                    position,
                    zoom,
                    popups
                },
                show_team_members,
                show_content,
                content {
                    heading,
                    heading_tag,
                    class_tag,
                    show_image,
                    slider_direction,
                    class_identifier,
                    images {
                        url,
                        alt,
                        show_tag,
                        background_color
                    },
                    html_content
                },
                show_group_links,
                group_links_type,
                group_links {
                    title,
                    links {
                        linksource,
                        linkname,
                        revised,
                        icon
                    }
                },
                show_faq_list,
                faq_list {
                    question,
                    answer
                },
                show_card_group,
                card_group_type,
                card_group {
                    group_title,
                    group_description,
                    group {
                        title,
                        image,
                        show_full_excerpt,
                        excerpt,
                        buttons {
                            link,
                            button,
                            rel,
                            targetLink
                        }
                    }
                },
                table_content {
                    table_id,
                    table_title,
                    header {
                        label
                    }
                    rows {
                        col_id,
                        columns {
                            description
                        }
                    }
                } 
            }
        }
        members: allMembersJson(
            filter: {id: { in: $member}}
            sort: {fields: [id], order: ASC}
            ){
            totalCount
            edges {
                node {
                    id,
                    title,
                    is_expert,
                    image,
                    tags,
                    content
                }
            }
        }
        related: allProductsJson (
            filter: {group: { eq: $related}}
            sort: {fields: [id], order: ASC}
        ) {
            edges {
                node {
                    id,
                    url,
                    name,
                    group,
                    start_price,
                    image_thumb,
                    button,
                    excerpt
                }
            }
        }
        product: allProductsJson(
            filter: {category: {eq: $product}}
        ) {
            edges {
                node {
                    name,
                    id,
                    start_price,
                    url,
                    image_thumb,
                    button,
                    excerpt,
                    tab {
                        tab_name,
                        id,
                        tab_content_html
                    },
                    image_gallery {
                        image,
                        alt
                    },
                    variations {
                        sku,
                        product_name,
                        excerpt,
                        us_price
                    }
                }
            }
        } 
    }
`