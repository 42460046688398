import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import GenButton from "../components/gen-button"
import PropTypes from "prop-types"
import Header from '../components/header'
import * as cardStyles from './card.module.css'

export default function Card(props) {
    let title, excerpt, img, btn
    
    let imgSource = "images/cards/" + props.image
    const data = useStaticQuery(graphql`
        query {
            images: allFile(filter: {relativePath: {regex: "/images\/cards/"}}) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fixed (width: 100 ) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    `)

    if(props.title.length > 0) {
        title = <Header headerText={props.title} headingTag="h4" classTag="h5" />
    }
    
    if(props.excerpt.length > 0) {
        if(props.html) {
            function createMarkup() {
                return {__html: props.excerpt}
            }
            excerpt = <p dangerouslySetInnerHTML={createMarkup()}></p>
        } else {
            excerpt = <p>{props.excerpt}</p>
        }
        
    }

    if(props.image.length > 0) {
        const image = data.images.edges.find(n => {
            return n.node.relativePath.includes(imgSource);
        });

        if (!image) {
            return null;
        }

        img = <Img alt={props.title + ' image'} fixed={image.node.childImageSharp.fixed} className="roundImg100" />
    }

    if(props.buttons.length > 0) {
        btn = [];
        let computedBtn = props.buttons;
        computedBtn.forEach((el, index) => {
            btn.push(<GenButton key={index} link={el.link} button={el.button} rel={el.rel} target={el.targetLink} />)
        })
    }

    return (
        <div className={cardStyles.card}>
            {img}
            {title}
            {excerpt}
            {btn}
        </div>
    )
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    buttons: PropTypes.array,
    show_full_excerpt: PropTypes.bool
}

Card.defaultProps = {
    show_full_excerpt: false
}