import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import * as linkstyles from "./links.module.css"

const LinkCard = ({title, links}) => {
    let linksArr = [];

    links.forEach((el, index) => {
        let curLink;
        if(el.icon === "internal.svg") {
            curLink = <li key={index + "link"}><span><img src={"/icons/" + el.icon} width="12" className={linkstyles.icon} alt={"icon image" + el.icon} /></span><Link to={"/" + el.linksource}>{el.linkname}</Link></li>;
            linksArr.push(curLink);
        } else if(el.icon === "external.svg") {
            curLink = <li key={index + "link"}><span><img src={"/icons/" + el.icon} width="12" className={linkstyles.icon} alt={"icon image" + el.icon} /></span><a href={el.linksource} target="_blank" rel="noopener noreferrer">{el.linkname}</a></li>;
            linksArr.push(curLink);
        } else {
            curLink = <li key={index + "link"}><span><img src={"/icons/" + el.icon} width="12" className={linkstyles.icon} alt={"icon image" + el.icon} /></span><a href={"/documents/" + el.linksource} download>{el.linkname}</a></li>;
            linksArr.push(curLink);
        }

    })

    return (
        <div className={linkstyles.linkcard}>
            <Header headerText={title} classTag="h5" headingTag="h4" />
            <ul className={linkstyles.listgroup}>
               {linksArr}
            </ul>
        </div>
    )
}

export default function GroupLinks({linkcontent}) {
    return (
        <section className="small">
            <div className={linkstyles.linkgroup}>
            {linkcontent.map((el, index) => (
                <LinkCard key={index + "groupLink"} title={el.title} links={el.links} />
            ))}
            </div>
            </section>
    )
}