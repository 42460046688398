import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Header from "../components/header"

const CtnElement = function({heading, headingTag, classTag, images, htmlContent}) {
    let title, img, html
    function createMarkup() {
        return {__html: htmlContent}
    }

    const data = useStaticQuery(graphql`
        query{
            contentimg: allFile(filter: {relativePath: {regex: "/images\/pages|images\/products/"}}) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 400) {
                                ...GatsbyImageSharpFluid
                              }
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    `)

    if(heading.length > 0){
        title = <Header headerText={heading} headingTag={headingTag} classTag={classTag} />;
    }

    if(images.length > 0) {
        img = [];
        
        images.forEach((el, index) =>{

            let imgSource = el.url;
            const image = data.contentimg.edges.find(n => {
                return n.node.relativePath.includes(imgSource)
            })
            
            if(!image) {
                return null;
            }
            
            img.push(<div key={index} style={{margin: "0 auto 2rem auto", maxWidth: "60rem"}} ><Img fluid={image.node.childImageSharp.fluid} /></div>)
        })
    } 

    if(htmlContent.length > 0){
        html = <div dangerouslySetInnerHTML={createMarkup()}></div>
    }

    return (
        <div className="content-card">
            {img}
            {title}
            {html}
        </div>
    )
}

export default function PageContent(props) {
    const ctn = props.mainContent
    const ctnMain = ctn.content
    let computedArr = []

    ctnMain.forEach((el, index) => {
        let curElement = <CtnElement key={index} heading={el.heading} htmlContent={el.html_content} images={el.images} headingTag={el.heading_tag} classTag={el.class_tag} />
        computedArr.push(curElement)
    })

    return (
        <main>
            { computedArr }
        </main>
    )
}