import React, { useEffect }  from "react"
import PropTypes from "prop-types"
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as genmapstyles from "./gen-map.module.css"

export default function GenMap(props) {

        const {position, zoom, pops} = props;
        
        useEffect(() => {
            // To get around an issue with the default icon not being set up right between using React
            // and importing the leaflet library, we need to reset the image imports
            // See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
            
            delete L.Icon.Default.prototype._getIconUrl;
        
            L.Icon.Default.mergeOptions({
              iconRetinaUrl: require( 'leaflet/dist/images/marker-icon-2x.png' ),
              iconUrl: require( 'leaflet/dist/images/marker-icon.png' ),
              shadowUrl: require( 'leaflet/dist/images/marker-shadow.png' ),
            });
            
          }, []);

        let markers = [];

        pops.forEach((el, index) => {
            let curmarker;
            curmarker = <Marker key={index} position={el} />
            markers.push(curmarker);
        })

        return (
            <div className={genmapstyles.mapid}>
                {(typeof window !== 'undefined') ? 
                    (
                            
                        <MapContainer center={position} zoom={zoom} scrollWheelZoom={false} >
                    <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {markers}
                    </MapContainer>
                    ) : null }
           </div>
        )
    
}

GenMap.propTypes = {
    position: PropTypes.array,
    zoom: PropTypes.number,
    pops: PropTypes.array
}

GenMap.defaultProps = {
    position: [0, 0],
    zoom: 10,
    pops: [[0, 0]]
}