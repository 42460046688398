import React, { useState } from "react"
import { Link, useStaticQuery, graphql} from "gatsby"
import Header from "../components/header"
import * as gensearchstyles from "./gen-search.module.css"

const Results = ({queryresults, query}) => {
  let newQuery = []
  let finalResult;

  if(queryresults){
    if(queryresults.length > 0) {
      queryresults.forEach(({node}, index) => {
        let curResult;
        if(node.childPagesJson !== null) {
          curResult = <li key={index}><Link to={"/" + node.childPagesJson.url}>{node.childPagesJson.name}</Link></li>
        } else {
          curResult = <li key={index}><Link to={"/product/"+ node.childrenProductsJson[0].url}>{node.childrenProductsJson[0].name}</Link></li>
        }
        newQuery.push(curResult)
      })
      finalResult = <ul>{newQuery}</ul>
    } else if (queryresults.length === 0 && query.length > 0) {
      finalResult = <p>Sorry, we didn't find what you are looking for. Try another term or send us your questions by filling out the form below or by calling 708-234-1200.</p>
    }
  } else {
    finalResult = <p>Nothing to show for now.</p>
  }
 
  return (
    <div className="content-card">
      {finalResult}
    </div>
  )
}

export default function GenSearch() {
    const data = useStaticQuery(graphql`
        query{
            pages: allFile(filter: {relativePath: {regex: "/data\/pages\/|data\/products\//"}}) {
                edges {
                  node {
                    relativePath,
                    childPagesJson {
                      url,
                      name,
                      seo {
                        keywords
                      }
                    },
                    childrenProductsJson {
                      url,
                      name,
                      seo {
                        keywords
                      }
                    }
                  }
                }
              }
        }
    `)

    let queryFilter;

    const [query, setQuery] = useState("")

    const handleQuery = event => {
        setQuery(event.target.value)
    }

    if(query.length > 0) {
        let regex, str;
        regex = new RegExp(query);
        queryFilter = data.pages.edges.filter(n => {
            if(n.node.childPagesJson !== null){
                str = n.node.childPagesJson.seo.keywords
                return regex.test(str.toLowerCase())
            } else if(n.node.childrenProductsJson.length > 0){
                str = n.node.childrenProductsJson[0].seo.keywords;
                return regex.test(str.toLowerCase())
            } else {
                return null
            }
        });
    }

    return (
        <div>
            <div className={gensearchstyles.field}>
              <Header headerText="Type in a term" classTag="h3" headingTag="h2" />
              <input value={query} onChange={handleQuery} className={gensearchstyles.input} placeholder="ex. rsid, hyliter, onetouch, amplicon, iplex or docs" />
            </div>
            <Results queryresults={queryFilter} query={query} />
        </div>
    )
}