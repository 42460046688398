import React from "react"
import Header from "../components/header"
import * as gentablestyles from "./gen-table.module.css"

const Row = ({colid, cols}) => {
    return (
        <tr>
            {
                cols.map((el, index) => (
                    <td key={index}>{el.description}</td>
                ))
            }
        </tr>
    )
}

const Table = ({header, rows, tableid, title}) => {
    return(
        <div className={gentablestyles.overflow}>
            <Header headerText={title} classTag="h2" headingTag="h2" />
            <table className={[gentablestyles.gentable, gentablestyles.overflow].join(' ')}>
                <thead>
                    <tr>
                        {
                            header.map((el, index)=>(
                                <th key={index}>{el.label}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((el, index) => (
                            <Row key={index} colid={el.col_id} cols={el.columns} />
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default function GenTable({tablecontent}) {
    return (
        <div>
            {tablecontent.map((el, index) =>(
                <Table key={index} header={el.header} rows={el.rows} tableid={el.table_id} title={el.table_title} />
            ))}
        </div>
    )
}